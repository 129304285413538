<template>
  <div id="footer">
    <footer class="footer">
      <nav class="level">
        <div class="level-item has-text-centered">
          <ul>
            <li>
              <a
                class="navbar-item"
                target="_blank"
                href="https://www.facebook.com/glassyladiesart"
              >
                <span class="iconify-inline" data-icon="mdi-facebook"></span>
                <span>Like Us on Facebook</span>
              </a>
            </li>
            <li>
              <a
                class="navbar-item"
                target="_blank"
                href="mailto:liz@theglassyladies.com"
              >
                <span class="iconify-inline" data-icon="mdi-email"></span>
                <span>Write to Us</span>
              </a>
            </li>
          </ul>
        </div>

        <div class="level-item has-text-centered">
          <ul>
            <li>&copy; {{ year }} The Glassy Ladies, LLC</li>
            <li>
              Experiencing issues?
              <a href="mailto:webmaster@theglassyladies.com">Let us know.</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
