import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Care from "../views/Care.vue";
import Faq from "../views/Faq.vue";
import Admin from "../views/Admin.vue";
import Cart from "../views/Cart.vue";
import Checkout from "../views/Checkout.vue";
import Privacy from "../views/Privacy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/admin",
    name: "Administration",
    component: Admin
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout
  },
  {
    path: "/faq",
    name: "F.A.Q.",
    component: Faq
  },
  {
    path: "/care",
    name: "Care & Handling",
    component: Care
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "is-active"
});

export default router;
