<template>
  <div id="navbar">
    <nav class="navbar is-fixed-top is-primary">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img src="@/assets/logo_sm.png" />
          <div class="navbar-item">
            <span style="color: white">
              The Glassy Ladies
            </span>
          </div>
        </router-link>
        <a class="navbar-burger burger" v-on:click="toggleNavMenu">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div :class="navMenu">
        <div class="navbar-start">
          <router-link to="/faq" class="navbar-item">
            F.A.Q.
          </router-link>
          <router-link to="/about" class="navbar-item">
            About Us
          </router-link>
          <router-link to="/care" class="navbar-item">
            Care & Handling
          </router-link>
          <router-link to="/privacy" class="navbar-item">
            Privacy Policy
          </router-link>
          <router-link to="/admin" class="navbar-item">
            Inventory
          </router-link>
        </div>

        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__flipInX"
          leave-active-class="animate__animated animate__flipOutX"
        >
          <div
            class="navbar-end"
            v-if="routeName != 'Administration'"
            key="cartCheckout"
          >
            <div
              class="navbar-item has-dropdown is-active"
              v-for="category in categories"
              :key="category"
            >
              <div class="navbar-link">
                {{ category }}
              </div>
            </div>

            <div class="navbar-item">
              <CartCheckout></CartCheckout>
            </div>
          </div>
        </transition>
      </div>
    </nav>
  </div>
</template>

<script>
import CartCheckout from "@/components/CartCheckout.vue";

export default {
  name: "Navbar",
  components: {
    CartCheckout
  },
  data() {
    return {
      isMenuActive: false
    };
  },
  computed: {
    navMenu() {
      if (this.isMenuActive) {
        return "navbar-menu is-active";
      } else {
        return "navbar-menu";
      }
    },
    categories() {
      //const raw = this.$store.getters.products.map(p => p.category.split("/"));
      //const raw = [
      //  [1, 2, 3],
      //  [1, 2, 4],
      //  [1, 5],
      //  [6, 7]
      //];
      return [];
    },
    routeName() {
      return this.$route.name;
    }
  },
  methods: {
    generateCategories() {
      let results = [];

      return results;
    },
    toggleNavMenu() {
      this.isMenuActive = !this.isMenuActive;
    }
  }
};
</script>
