<template>
  <div id="addNewProduct">
    <button class="button is-primary is-medium is-rounded" @click="toggleModal">
      + Add New
    </button>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <div class="modal is-active" v-if="modalEnabled">
        <div class="modal-background"></div>
        <div
          class="modal-card animate__animated animate__slideInDown animate__faster"
        >
          <header class="modal-card-head">
            <p class="modal-card-title">Add a new product</p>
            <button class="delete" @click="toggleModal"></button>
          </header>
          <section class="modal-card-body">
            <ProductEditCard v-bind:oldid="-1"></ProductEditCard>
          </section>
          <footer class="modal-card-foot"></footer>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ProductEditCard from "@/components/admin/ProductEditCard";

export default {
  name: "NewProduct",
  components: {
    ProductEditCard
  },
  data: function() {
    return {
      modalEnabled: false
    };
  },
  computed: {
    numProducts: function() {
      return this.$store.getters.products.length;
    }
  },
  watch: {
    numProducts: function() {
      this.modalEnabled = false;
    }
  },
  methods: {
    toggleModal() {
      this.modalEnabled = !this.modalEnabled;
    }
  }
};
</script>
