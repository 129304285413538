<template>
  <div id="productSearch">
    <div class="field is-grouped is-grouped-multiline">
      <div class="control is-expanded has-icons-left">
        <input
          class="input is-medium is-primary is-rounded"
          type="search"
          placeholder="Find something in particular..."
          v-model.trim="term"
          @input="updateSearch"
          autofocus
        />
        <span class="icon is-left">
          <span class="iconify-inline" data-icon="mdi-magnify"></span>
        </span>
      </div>
      <div class="control has-icons-left">
        <div class="select is-medium is-primary is-rounded">
          <select v-model="sortOptionName" @change="updateSort">
            <option v-for="name in sortOptionNames" :key="name" :value="name">
              {{ name }}
            </option>
          </select>
        </div>
        <span class="icon is-left">
          <span class="iconify-inline" data-icon="mdi-sort"></span>
        </span>
      </div>
    </div>
    <content class="has-text-centered" v-if="noResults">
      <p>
        Couldn't find what you're looking for?
        <a
          href="mailto:liz@theglassyladies.com?subject=Custom Order Request&body=Please describe what you are looking for and we will be in touch."
          >We do custom orders too!</a
        >
      </p>
    </content>
  </div>
</template>

<script>
export default {
  name: "ProductSearch",
  data() {
    return {
      term: "",
      searchTimer: function() {},
      sortOptions: {
        "Featured Items": a => (a.featured ? -1 : 1),
        "In Stock": (a, b) => a.quantity < b.quantity,
        "Made to Order": (a, b) => a.quantity > b.quantity,
        "A to Z": (a, b) => a.name > b.name,
        "Z to A": (a, b) => a.name < b.name,
        "Newest to Oldest": (a, b) => a.id < b.id,
        "Oldest to Newest": (a, b) => a.id > b.id,
        "Price (Low to High)": (a, b) => a.cents > b.cents,
        "Price (High to Low)": (a, b) => a.cents < b.cents
      },
      sortOptionName: String
    };
  },
  created: function() {
    this.sortOptionName = this.sortOptionNames[0];
  },
  computed: {
    sortOptionNames() {
      return Object.keys(this.sortOptions);
    },
    noResults() {
      return !this.$store.getters.products.length && !this.$store.getters.busy;
    }
  },
  methods: {
    updateSearch() {
      clearTimeout(this.searchTimer);
      this.timeout = setTimeout(() => {
        this.$store.commit("searchTerm", this.term);
      }, 1000);
    },
    updateSort() {
      this.$store.commit("compare", this.sortOptions[this.sortOptionName]);
    }
  }
};
</script>


