<template>
  <div id="admin">
    <div class="container">
      <section class="section">
        <div class="columns">
          <div class="column is-narrow">
            <NewProduct></NewProduct>
          </div>
          <div class="column">
            <ProductSearch></ProductSearch>
          </div>
        </div>
        <ProductEditList></ProductEditList>
      </section>
    </div>
  </div>
</template>

<script>
import NewProduct from "@/components/admin/NewProduct.vue";
import ProductSearch from "@/components/ProductSearch.vue";
import ProductEditList from "@/components/admin/ProductEditList.vue";

export default {
  name: "Admin",
  components: {
    ProductEditList,
    NewProduct,
    ProductSearch
  },
  beforeRouteEnter: (to, from, next) => {
    if (!process.env.VUE_APP_LOGIN_URL) {
      next();
    } else {
      fetch(process.env.VUE_APP_LOGIN_URL).then(res => {
        if (res.status == 401) {
          next(false);
        } else {
          next();
        }
      });
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
