<template>
  <!-- Home is a view for browsing through the primary inventory. It should
	  allow users to sort, filter, and search for items and add them to their
	  cart. -->

  <div id="home">
    <ProductDetail></ProductDetail>
    <div class="columns">
      <div class="column">
        <div class="container">
          <section class="section">
            <ProductSearch></ProductSearch>
          </section>
          <section class="section">
            <ProductList></ProductList>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import ProductSearch from "@/components/ProductSearch.vue";
import ProductDetail from "@/components/ProductDetail.vue";

export default {
  name: "Home",
  components: {
    ProductList,
    ProductDetail,
    ProductSearch
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
