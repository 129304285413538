<template>
  <div id="app">
    <div id="main">
      <Navbar></Navbar>
      <BusyBar></BusyBar>
      <transition
        mode="out-in"
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
      >
        <router-view />
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import BusyBar from "@/components/BusyBar.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    BusyBar,
    Navbar,
    Footer
  }
};
</script>

<style lang="scss">
@charset "utf-8";

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");
$family-sans-serif: "Oxygen", sans-serif;

/* Custom colors */
$viridian-green: #2b9292ff;
$cultured: #f4f6f6ff;
$gainsboro: #dee5e5ff;
$queen-blue: #41668cff;
$madder-lake: #cc2936ff;
$rich-black-fogra-29: #001011ff;

/* Color overrides */
$primary: $viridian-green;
$danger: $madder-lake;
$info: $queen-blue;
$link: $queen-blue;
$black: $rich-black-fogra-29;
$scheme-main: $cultured;
$scheme-main-bis: $gainsboro;

/* Resizing */
$modal-content-width: 50em;

/* Import only what you need from Bulma */
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/generic.sass";
@import "../node_modules/bulma/sass/base/minireset.sass";
@import "../node_modules/bulma/sass/elements/box.sass";
@import "../node_modules/bulma/sass/elements/other.sass";
@import "../node_modules/bulma/sass/elements/icon.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/content.sass";
@import "../node_modules/bulma/sass/elements/image.sass";
@import "../node_modules/bulma/sass/elements/progress.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/form/shared.sass";
@import "../node_modules/bulma/sass/form/shared.sass";
@import "../node_modules/bulma/sass/form/input-textarea.sass";
@import "../node_modules/bulma/sass/form/select.sass";
@import "../node_modules/bulma/sass/form/file.sass";
@import "../node_modules/bulma/sass/form/tools.sass";
@import "../node_modules/bulma/sass/components/card.sass";
@import "../node_modules/bulma/sass/components/dropdown.sass";
@import "../node_modules/bulma/sass/components/level.sass";
@import "../node_modules/bulma/sass/components/modal.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/components/breadcrumb.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";
@import "../node_modules/bulma/sass/helpers/color.sass";
@import "../node_modules/bulma/sass/helpers/flexbox.sass";
@import "../node_modules/bulma/sass/helpers/typography.sass";
@import "../node_modules/bulma/sass/helpers/visibility.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/layout/footer.sass";

/* Force footer to bottom of page */
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
#main {
  flex: 1;
}
</style>
