<template>
  <div id="cart">
    <div class="container">
      <div v-if="items.length" class="section">
        <div class="columns">
          <div class="column is-one-third">
            <Totals></Totals>
          </div>
          <div class="column">
            <h1 class="subtitle">Your Shopping Cart</h1>
            <CartItem
              v-for="item in items"
              :key="item[0]"
              v-bind:id="item[0] * 1"
              v-bind:in-cart="item[1]"
            ></CartItem>
          </div>
        </div>
      </div>
      <div v-else class="section">
        <div class="content">
          <p class="has-text-centered">
            There's nothing in your cart.
            <a> <router-link to="/">Start shopping!</router-link></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/cart/CartItem.vue";
import Totals from "@/components/cart/Totals.vue";

export default {
  name: "Cart",
  computed: {
    items() {
      return Object.entries(this.$store.state.cart);
    }
  },
  components: {
    CartItem,
    Totals
  }
};
</script>
