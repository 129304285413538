import Vue from "vue";
import Vuex from "vuex";
import Dichroism from "@/api/dichroism.js";

Vue.use(Vuex);

let dichroism = new Dichroism();

export default new Vuex.Store({
  state: {
    searchTerm: "",
    products: [],
    cart: {},
    busy: false,
    compare: a => (a.featured ? -1 : 1),
    productDetailId: 0
  },
  getters: {
    products(state) {
      let term = state.searchTerm.toLowerCase();
      return state.products
        .filter(item => {
          return [item.id, item.name, item.description, item.category]
            .join("")
            .toLowerCase()
            .includes(term);
        })
        .sort(state.compare);
    },
    cartTotal(state) {
      let cents = state.products
        .filter(p => state.cart[p.id])
        .map(p => p.cents * state.cart[p.id])
        .reduce((acc, cur) => acc + cur, 0);
      return "$ " + (cents / 100).toFixed(2);
    }
  },
  mutations: {
    toggleBusy(state) {
      state.busy = !state.busy;
    },
    productDetailId(state, id) {
      state.productDetailId = id;
    },
    cartItem(state, { id, by }) {
      let newCount = 1;
      if (state.cart[id]) {
        newCount = state.cart[id] += by;
        //if (newCount <= 0) {
        //  // remove from cart entirely
        //  delete state.cart[id];
        //  return;
        //}
        //state.cart = {
        //  ...state.cart,
        //  [id]: newCount
      }

      let cart = {
        ...state.cart
      };

      if (newCount) {
        cart[id] = newCount;
      } else {
        // remove entirely
        delete cart[id];
      }
      state.cart = cart;
    },
    removeItemFromCart(state, id) {
      if (state.cart[id]) {
        let cart = {
          ...state.cart
        };
        delete cart[id];
        state.cart = cart;
      }
    },
    compare(state, compare) {
      state.compare = compare;
    },
    searchTerm(state, term) {
      state.searchTerm = term;
    },
    setProducts(state, products) {
      if (products) {
        state.products = products;
      }
    }
  },
  actions: {
    async refreshProducts({ commit }) {
      commit("toggleBusy");
      const products = await dichroism.getProducts();
      commit("setProducts", products);
      commit("toggleBusy");
    },
    async updateProduct({ commit, dispatch }, product) {
      commit("toggleBusy");
      await dichroism.updateProduct(product);
      dispatch("refreshProducts");
      commit("toggleBusy");
    },
    async createProduct({ commit, dispatch }, product) {
      commit("toggleBusy");
      await dichroism.createProduct(product);
      dispatch("refreshProducts");
      commit("toggleBusy");
    },
    async createPhotoSet({ commit }, file) {
      commit("toggleBusy");
      const photoSet = await dichroism.createPhoto(file);
      commit("toggleBusy");
      return photoSet;
    }
  }
});
