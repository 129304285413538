<template>
  <div id="checkout">
    <CheckoutForm></CheckoutForm>
  </div>
</template>

<script>
import CheckoutForm from "@/components/checkout/CheckoutForm.vue";
export default {
  name: "Checkout",
  components: { CheckoutForm }
};
</script>
