<template>
  <div>
    <h1 class="subtitle has-text-centered">
      Total
    </h1>
    <hr />

    <h1 class="title has-text-centered">{{ cartTotal }}</h1>
    <router-link
      to="/checkout"
      class="button is-success is-fullwidth is-rounded is-medium"
    >
      Continue to Checkout
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Totals",
  computed: {
    cartTotal() {
      return this.$store.getters.cartTotal;
    }
  },
  methods: {}
};
</script>
