<template>
  <div class="container">
    <form action="#">
      <section class="section">
        <h1 class="title">Customer Info</h1>
        <hr />

        <div class="columns">
          <div class="column">
            <div class="field">
              <label for="firstName" class="label">First Name</label>

              <div class="control has-icons-left">
                <input
                  v-model="formData.firstName"
                  id="firstName"
                  class="input is-rounded"
                  type="text"
                  required
                />
                <span class="icon is-left">
                  <span
                    class="iconify-inline"
                    data-icon="mdi-card-account-details"
                  ></span>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Last Name</label>

              <div class="control has-icons-left">
                <input
                  v-model="formData.lastName"
                  class="input is-rounded"
                  type="text"
                  required
                />
                <span class="icon is-left">
                  <span
                    class="iconify-inline"
                    data-icon="mdi-card-account-details"
                  ></span>
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label">Email Address</label>

              <div class="control has-icons-left">
                <input
                  v-model="formData.email"
                  class="input is-rounded"
                  type="email"
                  required
                />
                <span class="icon is-left">
                  <span class="iconify-inline" data-icon="mdi-email"></span>
                </span>
              </div>
              <p class="help">Example: you@example.com</p>
            </div>

            <div class="field">
              <label class="label">Phone Number</label>
              <div class="control has-icons-left">
                <input
                  v-model="formData.phone"
                  class="input is-rounded"
                  type="tel"
                  pattern="[0-9]{10}"
                  required
                />
                <span class="icon is-left">
                  <span
                    class="iconify-inline"
                    data-icon="mdi-phone-classic"
                  ></span>
                </span>
              </div>
              <p class="help">Example: 7571234567</p>
            </div>

            <label class="checkbox">
              <input v-model="formData.sms" type="checkbox" />
              <span class="iconify" data-icon="mdi-cellphone-android"></span>
              May we text order updates and questions to this number?
            </label>
          </div>
          <div class="column">
            <label class="label">Location</label>
            <div class="field has-addons">
              <div class="control has-icons-left">
                <div class="select is-rounded">
                  <select required>
                    <option selected>USA</option>
                  </select>
                </div>
                <span class="icon is-left">
                  <span
                    class="iconify-inline"
                    data-icon="mdi-flag-variant"
                  ></span>
                </span>
              </div>

              <div class="control has-icons-left">
                <div class="select is-rounded">
                  <select v-model="formData.state" required>
                    <option v-for="state in states" :key="state">{{
                      state
                    }}</option>
                  </select>
                </div>
                <span class="icon is-left">
                  <span class="iconify-inline" data-icon="mdi-map"></span>
                </span>
              </div>
            </div>
            <p class="help">
              If you are located outside of the United States, please
              <a href="mailto:liz@theglassyladies.com"
                >contact us directly regarding your order</a
              >
            </p>

            <div class="field">
              <label class="label">Address</label>

              <div class="control has-icons-left">
                <input
                  v-model="formData.address1"
                  class="input is-rounded"
                  type="text"
                  required
                />
                <span class="icon is-left">
                  <span
                    class="iconify-inline"
                    data-icon="mdi-road-variant"
                  ></span>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Address 2 (Optional)</label>

              <div class="control has-icons-left">
                <input
                  v-model="formData.address2"
                  class="input is-rounded"
                  type="text"
                />
                <span class="icon is-left">
                  <span
                    class="iconify-inline"
                    data-icon="mdi-format-list-numbered"
                  ></span>
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label">City</label>
              <div class="control has-icons-left">
                <input
                  v-model="formData.city"
                  class="input is-rounded"
                  type="text"
                  required
                />
                <span class="icon is-left">
                  <span class="iconify-inline" data-icon="mdi-city"></span>
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label">Zip Code</label>

              <div class="control has-icons-left">
                <input
                  v-model="formData.zip"
                  class="input is-rounded"
                  type="text"
                  pattern="[0-9]{5}"
                  required
                />
                <span class="icon is-left">
                  <span class="iconify-inline" data-icon="mdi-mailbox"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <h1 class="title">Payment & Delivery</h1>
        <hr />

        <div class="content">
          <p>
            You will be contacted directly regarding payment and delivery
            options.
          </p>
        </div>
      </section>

      <section class="section">
        <h1 class="title">Finish</h1>
        <hr />
        <h1 class="subtitle">Confirm your order of {{ cartTotal }}?</h1>
        <button class="button is-success is-rounded is-medium">
          Place Order
        </button>
      </section>
    </form>
  </div>
</template>

<script>
export default {
  name: "CheckoutForm",
  data() {
    return {
      states: [
        "Virginia",
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
      ],
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "Virginia",
        country: "USA",
        zip: "",
        sms: false
      }
    };
  },
  computed: {
    cartTotal() {
      return this.$store.getters.cartTotal;
    }
  }
};
</script>
