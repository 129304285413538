export default class Product {
  constructor(json) {
    if (json) {
      this.id = json.id ? json.id : null;
      this.name = json.name ? json.name : null;
      this.description = json.description ? json.description : null;
      this.cents = Number.isFinite(json.cents) ? json.cents : null;
      this.quantity = Number.isFinite(json.quantity) ? json.quantity : null;
      this.featured = json.featured ? json.featured : false;
      this.category = json.category ? json.category : null;
      this.photo_base = json.photo_base ? json.photo_base : null;
      this.photo_thumbnail = json.photo_thumbnail ? json.photo_thumbnail : null;
      this.photo_fullsize = json.photo_fullsize ? json.photo_fullsize : null;
    }
  }
}
