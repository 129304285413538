<template>
  <div id="productDetail">
    <div v-if="product" class="modal is-active">
      <div @click="clearDetailId" class="modal-background"></div>
      <div class="modal-content">
        <div class="card">
          <div class="card-image">
            <figure class="image ">
              <a target="_blank" :href="fullsize">
                <img
                  :src="base"
                  :alt="product.name"
                  title="Click for fullsize"
                />
              </a>
            </figure>
          </div>

          <div class="card-content">
            <div class="columns">
              <div class="column">
                <p class="title">
                  {{ product.name }}
                </p>
                <nav class="breadcrumb">
                  <ul>
                    <li v-for="category in categories" :key="category">
                      {{ category }}
                    </li>
                  </ul>
                </nav>
                <p>
                  {{ product.description }}
                </p>
              </div>
              <div class="column is-one-third">
                <p class="subtitle">{{ dollars }}</p>
                <p class="subtitle">{{ stock }}</p>
                <div v-if="inCart" class="field has-addons">
                  <p class="control is-expanded">
                    <a
                      class="button is-static is-fullwidth is-rounded is-medium"
                    >
                      {{ inCart }} in cart
                    </a>
                  </p>
                  <div class="control">
                    <a
                      @click="incrementCartQuantity(-1)"
                      class="button is-info is-medium"
                    >
                      <span
                        class="iconify-inline"
                        data-icon="mdi-cart-minus"
                      ></span>
                    </a>
                  </div>
                  <div class="control">
                    <a
                      @click="incrementCartQuantity(1)"
                      class="button is-info is-rounded is-medium"
                    >
                      <span
                        class="iconify-inline"
                        data-icon="mdi-cart-plus"
                      ></span>
                    </a>
                  </div>
                </div>
                <button
                  v-else
                  @click="incrementCartQuantity(1)"
                  class="button is-success is-fullwidth is-rounded is-medium"
                >
                  <span
                    class="iconify-inline"
                    data-icon="mdi-cart-arrow-down"
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div @click="clearDetailId" class="modal-close is-large"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDetail",
  computed: {
    inCart() {
      return this.$store.state.cart[this.product.id];
    },
    product() {
      return this.$store.getters.products.find(
        p => p.id == this.$store.state.productDetailId
      );
    },
    stock() {
      if (this.product.quantity == 0) {
        return "Made to order";
      } else {
        return [this.product.quantity, "in stock"].join(" ");
      }
    },
    dollars() {
      return "$ " + (this.product.cents / 100).toFixed(2);
    },
    base() {
      return [process.env.VUE_APP_IMAGE_ROOT, this.product.photo_base].join("");
    },
    fullsize() {
      return [process.env.VUE_APP_IMAGE_ROOT, this.product.photo_fullsize].join(
        ""
      );
    },
    categories() {
      let categories = this.product.category.split("/");
      categories.splice(0, 1, "All");
      return categories;
    }
  },
  methods: {
    clearDetailId() {
      this.$store.commit("productDetailId", 0);
    },
    incrementCartQuantity(by) {
      this.$store.commit("cartItem", { id: this.product.id, by });
    }
  }
};
</script>
