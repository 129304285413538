<template>
  <div id="busyBar">
    <progress
      class="progress is-small is-primary"
      max="100"
      v-if="isBusy"
    ></progress>
  </div>
</template>

<script>
export default {
  name: "BusyBar",
  computed: {
    isBusy() {
      return this.$store.state.busy;
    }
  }
};
</script>
