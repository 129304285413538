<template>
  <div id="productCard">
    <div class="card">
      <div class="card-image">
        <figure class="image is-square">
          <a @click="setDetailId">
            <img :src="thumbnail" :alt="name" title="Click to expand." />
          </a>
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <p class="title is-4">
            <a @click="setDetailId">
              {{ name }}
            </a>
          </p>
          <p class="subtitle is-4">
            {{ dollars }}
          </p>
          <p class="subtitle is-6">{{ stock }}</p>
        </div>

        <div class="content">
          {{ shortDescription }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    id: Number,
    name: String,
    quantity: Number,
    cents: Number,
    photo_thumbnail: String,
    photo_base: String,
    photo_fullsize: String,
    description: String
  },
  computed: {
    stock() {
      if (this.quantity == 0) {
        return "Made to order";
      } else {
        return [this.quantity, "in stock"].join(" ");
      }
    },
    dollars() {
      return "$ " + (this.cents / 100).toFixed(2);
    },
    thumbnail() {
      return process.env.VUE_APP_IMAGE_ROOT + this.photo_thumbnail;
    },
    shortDescription() {
      let description = this.description.split(" ");
      if (description.length < 10) {
        return this.description;
      } else {
        return description.splice(0, 10).join(" ") + "…";
      }
    }
  },
  methods: {
    setDetailId() {
      this.$store.commit("productDetailId", this.id);
    }
  }
};
</script>
