<template>
  <div id="cartCheckout">
    <div class="buttons has-addons">
      <router-link
        v-if="inCart"
        to="/cart"
        class="button is-success is-rounded"
      >
        <span class="iconify-inline" data-icon="mdi-cart"></span>
        <span>Checkout</span></router-link
      >
      <a v-else class="button is-static is-rounded">
        <span class="iconify-inline" data-icon="mdi-cart"></span>
      </a>
      <button class="button is-static">x{{ inCart }}</button>
      <button class="button is-static is-rounded">
        {{ cartTotal }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartCheckout",
  computed: {
    inCart() {
      return Object.values(this.$store.state.cart).reduce(
        (acc, cur) => acc + cur,
        0
      );
    },
    cartTotal() {
      return this.$store.getters.cartTotal;
    }
  }
};
</script>
