<template>
  <div>
    <hr />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <p class="image is-64x64">
            <img :src="thumbnail" :title="product.name" />
          </p>
        </div>
        <div class="level-item">
          <div class="content">
            <strong>
              <p>
                {{ product.name }}
              </p>
            </strong>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">Subtotal: {{ dollars }}</div>
        <div class="level-item">
          <div class="field has-addons">
            <p class="control is-expanded">
              <a class="button is-static is-fullwidth is-rounded">
                {{ inCart }} in cart
              </a>
            </p>
            <div class="control">
              <a
                @click="incrementCartQuantity(-1)"
                class="button is-info is-rounded"
              >
                <span class="iconify-inline" data-icon="mdi-cart-minus"></span>
              </a>
            </div>
            <div class="control">
              <a
                @click="incrementCartQuantity(1)"
                class="button is-info is-rounded"
              >
                <span class="iconify-inline" data-icon="mdi-cart-plus"></span>
              </a>
            </div>
          </div>
        </div>

        <div class="level-item">
          <button class="button is-danger is-rounded" @click="removeAll">
            <span class="iconify-inline" data-icon="mdi-cart-remove"></span>
            <span>Remove</span>
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "CartItem",
  props: {
    id: Number,
    inCart: Number
  },
  computed: {
    product() {
      return this.$store.state.products.find(p => p.id == this.id);
    },
    dollars() {
      return "$ " + ((this.product.cents * this.inCart) / 100).toFixed(2);
    },
    thumbnail() {
      return (
        process.env.VUE_APP_IMAGE_ROOT + "/" + this.product.photo_thumbnail
      );
    }
  },
  methods: {
    incrementCartQuantity(by) {
      this.$store.commit("cartItem", { id: this.id, by });
    },
    removeAll() {
      this.$store.commit("removeItemFromCart", this.id);
    }
  }
};
</script>
