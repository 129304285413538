<template>
  <div id="productEditList">
    <div class="columns is-multiline is-variable is-desktop">
      <div
        class="column is-one-third-desktop"
        v-for="product in products"
        :key="product.id"
      >
        <ProductEditCard v-bind:oldid="product.id"></ProductEditCard>
      </div>
    </div>
  </div>
</template>

<script>
import ProductEditCard from "@/components/admin/ProductEditCard.vue";

export default {
  name: "ProductsList",
  components: {
    ProductEditCard
  },
  computed: {
    products() {
      return this.$store.getters.products;
    }
  },
  created() {
    this.$store.dispatch("refreshProducts");
  }
};
</script>
