export default class PhotoSet {
  id = 0;
  fullsize = "";
  thumbnail = "";
  base = "";

  constructor(json) {
    Object.assign(this, json);
  }
}
