import Product from "../models/product";
import PhotoSet from "../models/photo_set";
import ApiError from "./error";

export default class Dichroism {
  _base_addr = process.env.VUE_APP_API_BASE_ADDR;

  async createPhoto(file) {
    const fd = new FormData();
    fd.append(file.name, file);

    const options = {
      method: "POST",
      body: fd
    };

    try {
      const photos = await this._sendRequest("photos", options);
      return photos.map(p => new PhotoSet(p));
    } catch (err) {
      console.error("Dichroism: " + err.message);
      return null;
    }
  }

  async getProducts() {
    try {
      const products = await this._sendRequest("products", null);
      return products.map(p => new Product(p));
    } catch (err) {
      console.error("Dichroism: " + err.message);
      return [];
    }
  }

  async updateProduct(fieldDiff) {
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(fieldDiff)
    };

    try {
      const product = await this._sendRequest("products", options);
      return new Product(product);
    } catch (err) {
      console.error("Dichroism: " + err.message);
      return null;
    }
  }

  async createProduct(newProduct) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newProduct)
    };

    try {
      const product = await this._sendRequest("products", options);
      return new Product(product);
    } catch (err) {
      console.error("Dichroism: " + err.message);
      return null;
    }
  }

  async _sendRequest(endpoint, options) {
    const response = await fetch(this._base_addr + endpoint, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new ApiError(await response.text());
    }
  }
}
